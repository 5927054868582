import { Typography, Box, Button } from '@mui/material';
import jumbotronImg from '../assets/images/jumbotron.png';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

export default function Jumbotron() {
  const navigate = useNavigate();

  return (
    <Container>
      <JumbotronText
        sx={{
          padding: { xs: 5, lg: '100px 50px 50px 50px' },
          height: { xs: '100%', lg: 'auto' },
          width: { xs: '100%', lg: 'auto' },
          margin: { xs: 0, lg: '0 300px' },
          display: { xs: 'flex', lg: 'block' },
          flexDirection: { xs: 'column', lg: undefined },
          justifyContent: { xs: 'center', lg: undefined },
        }}
      >
        <Typography
          variant='h2'
          fontWeight='bold'
        >
          Welcome to Bl∞med Academy
        </Typography>
        <br />
        <br />
        <Typography>
          At Bl∞med Academy, we're passionate about supporting the next
          generation of physicians on their journey from medical school to
          residency and beyond. Inspired by a mission to make medical education
          accessible and empowering, BAM is here to provide students with the
          mentorship, resources, and guidance they need to thrive in a
          challenging and rewarding field.
        </Typography>
        <br />
        <br />
        <Box sx={{ display: 'flex', columnGap: 2 }}>
          <Button
            color='success'
            variant='contained'
            onClick={() => {
              navigate('/pricing?viewPackage=usmle-tutoring', {});
            }}
          >
            USMLE Tutoring
          </Button>
          <Button
            color='success'
            variant='contained'
            onClick={() => {
              navigate(
                '/pricing?viewPackage=life-coaching-mindset-bundle-packages'
              );
            }}
          >
            Private Coaching
          </Button>
          <Button
            color='success'
            variant='contained'
            onClick={() => {
              navigate('/pricing?viewPackage=residency-application-support');
            }}
          >
            Residency Applications
          </Button>
        </Box>
      </JumbotronText>
    </Container>
  );
}

const Container = styled.div`
  height: calc(100vh - 106px);
  background-image: url(${jumbotronImg});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const JumbotronText = styled(Box)`
  margin: ;
  color: white;
  background-color: rgb(0, 0, 0, 0.8);
`;
