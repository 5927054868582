import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from '@mui/material';
import PageHeader from '../common/PageHeader';
import { MuiTelInput } from 'mui-tel-input';
import styled from 'styled-components';
import { useRef, useState } from 'react';
import useEmailJs from '../../hooks/useEmailJs';
import { toast } from 'react-toastify';

export default function ContactUs() {
  const [phone, setPhone] = useState('');
  const { sendEmail } = useEmailJs();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const handleFormSubmission = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      await sendEmail(e.currentTarget);
      formRef.current?.reset();
      setPhone('');
      setDialogOpen(true);
    } catch (e) {
      toast.error('Failed to submit contact info.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 2, margin: { xs: 0, lg: '0 300px' } }}>
      <PageHeader title='Contact Us' />
      <form
        ref={formRef}
        onSubmit={handleFormSubmission}
      >
        <TextField
          label='Name'
          name='full_name'
          required
          fullWidth
        />
        <Spacing />
        <TextField
          label='Email'
          type='email'
          name='email'
          required
          fullWidth
        />
        <Spacing />
        <MuiTelInput
          defaultCountry='US'
          fullWidth
          label='Phone Number'
          name='phone_number'
          value={phone}
          onChange={(newValue) => setPhone(newValue)}
        />
        <Spacing />
        <TextField
          label='School Year'
          name='school_year'
          required
          fullWidth
        />
        <Spacing />
        <TextField
          label='What services are you interested in?'
          multiline
          rows={5}
          fullWidth
          required
          name='interest'
        />
        <Spacing />
        <Button
          type='submit'
          variant='contained'
          fullWidth
          disabled={loading}
        >
          Submit
          {loading && (
            <CircularProgress
              size={20}
              color='info'
              sx={{ ml: 1 }}
            />
          )}
        </Button>
      </form>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogContent>
          <DialogContentText>
            Thank you for your interest in Bl∞med Academy! We're thrilled to
            hear from you and excited to share more about how we can support you
            on your journey in medicine.
            <br />
            <br />
            At Bl∞med Academy, we are dedicated to empowering the next
            generation of physicians. Whether you're in medical school,
            preparing for residency, or looking for guidance as you navigate the
            field, we're here to provide the mentorship, resources, and support
            you need to succeed.
            <br />
            <br />
            A member of our team will get back to you shortly with more
            information. In the meantime, feel free to explore our website and
            learn more about our programs and services. If you have any specific
            questions or need immediate assistance, don't hesitate to reach out
            directly via our Instagram or Facebook pages.
            <br />
            <br />
            We look forward to connecting with you!
            <br />
            <br />
            Best regards,
            <br />
            The Bl∞med Academy Team
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const Spacing = styled.div`
  margin: 15px 0;
`;
