import React from 'react';
import NavBar from './components/Nav/NavBar';
import Jumbotron from './components/Jumbotron';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Tutors from './components/TutorsPage/Tutors';
import Pricing from './components/PricingPage/Pricing';
import ContactUs from './components/ContactUs/ContactUs';
import Testimonials from './components/Testimonials/Testimonials';

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <main>
        <>
          <Routes>
            <Route
              path='/'
              element={<Jumbotron />}
            />
            <Route
              path='/testimonials'
              element={<Testimonials />}
            />
            <Route
              path='/tutors'
              element={<Tutors />}
            />
            <Route
              path='/pricing'
              element={<Pricing />}
            />
            <Route
              path='/contact-us'
              element={<ContactUs />}
            />
          </Routes>
        </>
      </main>
    </BrowserRouter>
  );
}

export default App;
